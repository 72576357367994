<template>
  <el-dialog
    custom-class="ql-dialog "
    :title="isEdit ? '编辑点阵笔' : '新增点阵笔'"
    :visible="dialogVisible"
    width="529px"
    @close="handleCancel"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="112px">
      <el-form-item label="点阵笔编号" prop="dzbNo" size="small">
        <el-input
          :style="{ width: '100%' }"
          v-model="form.dzbNo"
          placeholder="请输入"
          maxlength="20"
          @focus="handleFocus"
          @blur="handleBlur"
        ></el-input>
      </el-form-item>
      <el-form-item label="点阵笔类型" prop="type" size="small">
        <el-select v-model="form.type" :style="{ width: '100%' }" @change="selectChange">
          <el-option v-for="item in types" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="[TYPE_STUDENT, TYPE_TEACHER].includes(form.type)"
        :label="nameLabel"
        class="is-required"
        size="small"
      >
        <student-select
          :defaultValue="studentSelectDefaultValue"
          :type="form.type"
          @select="handleStudentSelect"
        ></student-select>
      </el-form-item>
      <el-form-item v-if="form.type === TYPE_PUBLIC" label="班级内部学号" prop="classStuNo" size="small">
        <el-input
          :style="{ width: '100%' }"
          v-model="form.classStuNo"
          @input="
            (val) =>
              (form.classStuNo =
                Number(val.replace(/\D/g, '')) <= 1000 ? val.replace(/\D/g, '') : val.replace(/\D/g, '').slice(0, 3))
          "
        ></el-input>
        <p class="tip">班级内部学号可用于与公共点阵笔进行绑定，支持输入 0-1000的整数</p>
      </el-form-item>
      <el-form-item v-if="gradeVisible" label="所属年级" prop="gradeId" size="small">
        <el-select v-model="form.gradeId" :style="{ width: '100%' }">
          <el-option v-for="item in gradeList" :key="item.gradeId" :label="item.gradeName" :value="item.gradeId">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleSave">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';
// api
import { getWebSocketHost, savePen, getClassLessonGradeListByTeacher } from '@/api/equipment-manage';
// const
import { TYPE_STUDENT, TYPE_PUBLIC, TYPE_TEACHER } from '../constance';
// components
import { Form, FormItem, Dialog, Button, Input, Select, Option } from 'element-ui';
import StudentSelect from './select.vue';

export default {
  components: {
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    StudentSelect,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    isEdit: {
      type: Boolean,
      required: true,
      default: false,
    },
    // 用于编辑 传参
    defaultValue: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      // const
      TYPE_STUDENT,
      TYPE_PUBLIC,
      TYPE_TEACHER,

      dialogVisible: false,

      socket: null,
      socketIP: '',
      socketTimeout: null,

      types: [
        {
          id: TYPE_STUDENT,
          name: '学生点阵笔',
        },
        // {
        //   id: TYPE_PUBLIC,
        //   name: '公共点阵笔',
        // },
        {
          id: TYPE_TEACHER,
          name: '教师点阵笔',
        },
      ],

      gradeVisible: false,
      gradeList: [], // 年级列表 教师点阵笔

      form: {
        dzbNo: '',
        type: '',
        stuId: '', // 学生id/教师id - 学生点阵笔/教师点阵笔
        classStuNo: '', // 班级内部学号 - 公共点阵笔
        gradeId: '', // 年级 - 教师点阵笔
      },

      studentSelectDefaultValue: undefined,

      rules: {
        dzbNo: [
          { required: true, message: '请输入点阵笔编号', trigger: 'blur' },
          {
            pattern: /^([0-9a-fA-F]{2})(([/\s:][0-9a-fA-F]{2}){5})$/,
            message: '请输入正确的点阵笔编号',
            trigger: 'blur',
          },
        ],
        type: [{ required: true, message: '请选择点阵笔类型', trigger: 'blur' }],
        classStuNo: [{ required: true, message: '请输入班级内部学号', trigger: 'blur' }],
        gradeId: [{ required: true, message: '请选择所属班级', trigger: 'blur' }],
      },
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userInfo.userId,
    }),
    nameLabel() {
      if (this.form.type === TYPE_STUDENT) {
        return '学生姓名';
      }
      if (this.form.type === TYPE_TEACHER) {
        return '教师姓名';
      }
      return '';
    },
  },
  methods: {
    // handle 点阵笔编号 focus
    handleFocus() {
      const beginSocket = JSON.stringify({ key: 80001, userId: this.userId });
      this.socket?.send(beginSocket);
    },
    // handle 点阵笔编号 blur
    handleBlur() {
      const stopSocket = JSON.stringify({ key: 80003, userId: this.userId });
      this.socket?.send(stopSocket);
    },
    // handle 选择点阵笔类型 change
    selectChange() {
      this.form.stuId = '';
      this.gradeVisible = false;
      if (this.studentSelectDefaultValue) this.studentSelectDefaultValue = undefined;
    },
    //
    /**
     * @desc handle 搜索学生/教师
     * @param {string} id userId
     * @param {boolean} isInitalized 是否首次初始化
     */
    async handleStudentSelect(id, isInitalized = false) {
      this.form.stuId = id;
      if (!isInitalized) {
        this.form.gradeId = ''; // clear
      }
      await this.getClassLessonGradeListByTeacher();

      if (this.gradeList.length) {
        if (!isInitalized) {
          // 不是初始化（编辑）时，默认选中第一个
          this.form.gradeId = this.gradeList[0].gradeId;
        }
      } else {
        // gradeList.length为0，暂无任教
        this.gradeList = [
          {
            gradeId: null,
            gradeName: '暂无任教',
          },
        ];
        this.form.gradeId = null;
      }
      if (this.form.type === TYPE_TEACHER) {
        this.gradeVisible = true;
      }
      this.studentSelectDefaultValue = !undefined;
    },
    // handle 确定
    handleSave() {
      if (!this.studentSelectDefaultValue && this.form.type === TYPE_STUDENT) {
        this.$message.error('学生点阵笔学生姓名不能为空');
        return;
      }
      if (!this.studentSelectDefaultValue && this.form.type === TYPE_TEACHER) {
        this.$message.error('教师点阵笔教师姓名不能为空!');
        return;
      }
      if (this.form.type === TYPE_TEACHER && this.form.gradeId === null) {
        this.$message.error('该教师暂无任教，请先为该教师添加任教关系');
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          let params = {
            dzbNo: this.form.dzbNo,
            type: this.form.type,
            id: this.isEdit ? this.defaultValue.id : 0,
          };

          switch (this.form.type) {
            case TYPE_PUBLIC:
              params.classStuNo = this.form.classStuNo;
              break;
            case TYPE_STUDENT:
              params.stuId = this.form.stuId;
              break;
            case TYPE_TEACHER:
              params.stuId = this.form.stuId;
              params.gradeId = this.form.gradeId;
              break;
            default:
              break;
          }

          // api
          savePen(params).then(() => {
            this.$emit('success');
          });
        } else {
          return false;
        }
      });
    },
    // handle cancel
    handleCancel() {
      this.$emit('update:visible', false);
    },
    // api 获取websocket地址
    getSocketIP() {
      const protocol = location.protocol == 'https:' ? 'wss' : 'ws';

      return getWebSocketHost().then((res) => {
        this.socketIP = `${protocol}://` + res.result + '/websocket';
      });
    },
    // api 获取教师任教班级列表
    getClassLessonGradeListByTeacher() {
      return getClassLessonGradeListByTeacher({
        teacherId: this.form.stuId,
      }).then((res) => {
        this.gradeList = res.result;
      });
    },
    // util 开启websocket
    openWebScoket() {
      this.socket = new WebSocket(this.socketIP);

      this.socket.onopen = () => {
        const initSocket = JSON.stringify({ key: 40001, userId: this.userId, userType: 11 });
        this.socket.send(initSocket);
      };

      this.socketTimeout = setInterval(() => {
        const checkSocket = JSON.stringify({ key: 10001, clientType: 6 });
        this.socket?.send(checkSocket);
      }, 8000);

      this.socket.onclose = () => {
        clearInterval(this.socketTimeout);
        this.socketTimeout = null;
        this.socket = null;
      };

      //

      this.socket.onmessage = (res) => {
        const data = JSON.parse(res.data);
        if (res.data && data.key === 80002) {
          this.form.dzbNo = data.serialNumbers;
        }
      };
    },
  },
  watch: {
    visible: {
      handler: async function (val) {
        if (val) {
          // reset
          Object.assign(this.$data, this.$options.data());
          this.$nextTick(() => {
            this.$refs.form?.clearValidate();

            if (this.defaultValue?.id) {
              Object.assign(this.form, {
                dzbNo: this.defaultValue.dzbNo || '',
                type: String(this.defaultValue.type) || '',
                stuId: this.defaultValue.stuId || '', // 学生id/教师id-学生点阵笔/教师点阵笔
                classStuNo: this.defaultValue.classStuNo || '', // 班级内部学号-公共点阵笔
                gradeId: this.defaultValue.gradeId || '',
              });

              this.studentSelectDefaultValue = {
                id: this.defaultValue?.stuId || '',
                name: this.defaultValue?.stu?.userName || '',
              };
            }
          });

          // await this.getSocketIP();
          // this.openWebScoket();

          // set defaultValue
        }
        this.dialogVisible = val;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.tip {
  font-size: 12px;
  line-height: 1.5;
  margin-top: 5px;
  color: #b9b9b9;
}
</style>
