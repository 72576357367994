<template>
  <el-select
    v-model="userId"
    v-loading="isLoading"
    class="school-select"
    popper-class="school-select-popper"
    placeholder="请选择"
    style="width: 100%"
    :title="userName"
    @change="selectUser"
  >
    <i slot="prefix" class="iconfont icon-icon_sousuo"></i>
    <div slot="empty">
      <div class="select-input">
        <el-input
          ref="searchInput"
          :placeholder="placeholder"
          v-model="searchKey"
          @keyup.native.enter="getList"
          @keyup.native="debouncedOnSearch"
        >
          <i slot="prefix" class="iconfont icon-icon_sousuo"></i>
        </el-input>
      </div>
      <div class="no-data">无数据</div>
    </div>
    <div class="select-input" v-if="userList.length > 0">
      <el-input
        ref="searchInput"
        :placeholder="placeholder"
        v-model="searchKey"
        @keyup.native.enter="getList"
        @keyup.native="debouncedOnSearch"
      >
        <i slot="prefix" class="iconfont icon-icon_sousuo"></i>
      </el-input>
    </div>
    <el-option
      v-for="item in userList"
      :key="item.userId"
      :value="item.userId"
      :label="optionLabel(item)"
      :title="optionLabel(item)"
      :class="['text-center overflow-ellipsis', { active: item.userId === userId }]"
    ></el-option>
  </el-select>
</template>

<script>
// api
import { getStudentByNoOrName, getTeacherByNoOrName } from '@/api/equipment-manage';
// const
import { TYPE_STUDENT, TYPE_TEACHER } from '../constance';
// components
import { Input, Select, Option } from 'element-ui';
// debounce
import debounce from 'throttle-debounce/debounce';

export default {
  name: 'studentSelect',
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    // 默认选中（包含id, name)
    defaultValue: {
      type: Object,
      require: false,
      // default: {
      //   id: '',
      //   name: '',
      // },
    },
    type: {
      type: String,
      require: true,
      default: TYPE_STUDENT,
    },
  },
  data() {
    return {
      isLoading: false,

      searchKey: '',

      userId: '',
      userName: '',
      userList: [],

      manualSelected: false, // 手动选中
    };
  },
  computed: {
    placeholder() {
      if (this.type === TYPE_STUDENT) {
        return '请输入学号/姓名关键字进行检索';
      }
      if (this.type === TYPE_TEACHER) {
        return '请输入教职工号/姓名关键字进行检索';
      }
      return '请输入学号/姓名关键字进行检索';
    },
  },
  methods: {
    // handle 选择学校
    selectUser(id) {
      let user = this.userList.find((item) => item.userId == id);
      this.userName = user.userName;
      this.$emit('select', id);
      this.manualSelected = true;
    },
    // api 获取列表
    getList() {
      this.isLoading = true;

      let api = undefined;

      if (this.type === TYPE_STUDENT) {
        api = getStudentByNoOrName;
      }
      if (this.type === TYPE_TEACHER) {
        api = getTeacherByNoOrName;
      }

      return new Promise((resolve) => {
        api({
          search: this.searchKey.trim(),
        })
          .then((res) => {
            this.userList = res.result.length ? res.result : [];
            resolve(res.result);
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },
    optionLabel(data) {
      if (this.type === TYPE_STUDENT) {
        return data.studentNo ? `${data.userName}(${data.studentNo})` : data.userName;
      }
      if (this.type === TYPE_TEACHER) {
        return data.teacherNo ? `${data.userName}(${data.teacherNo})` : data.userName;
      }
      return data.userName;
    },
  },
  created() {
    this.debouncedOnSearch = debounce(800, () => {
      this.getList();
    });
  },
  watch: {
    defaultValue: {
      handler: async function (val) {
        // 编辑
        if (!this.manualSelected && val?.id) {
          // 默认自动选中
          this.searchKey = val.name;
          this.userId = val.id;
          this.userName = val.name;
          await this.getList();

          this.$emit('select', val.id, true);
        }
        // reset
        if (val === undefined) {
          Object.assign(this.$data, this.$options.data());
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.school-select {
  i {
    font-size: 16px;
    color: #6e7473;
    line-height: 40px;
  }
}

.select-input {
  margin: 6px 20px 16px;
  border-bottom: 1px solid #d6dedc;
}

.no-data {
  line-height: 160px;
  text-align: center;
  color: #999;
}

::v-deep .el-input__inner {
  padding-left: 45px;
}
</style>

<style lang="scss">
.school-select-popper {
  height: 304px;
  width: 380px;

  .select-input .el-input__inner {
    border: 0;
  }

  .el-select-dropdown__wrap {
    max-height: 304px;
    overflow-x: auto;
  }
}
.el-input__prefix {
  display: flex;
  align-items: center;
  left: 12px;
}
</style>
