/**
 * @desc 点阵笔
 */
// const
import { TYPE_STUDENT, TYPE_PUBLIC, TYPE_TEACHER } from './constance';
// api
import { getSchoolPrintPlan, getPenPageJSON, deletePen } from '@/api/equipment-manage';
// component
import { Table, TableColumn, Input, Button, Select, Option } from 'element-ui';
import basicHeader from '@/components/backStageHeader';
import searchInput from '@/components/searchInput';
import qlPagination from '@/components/qlPagination';
import Dialog from './components/dialog.vue';

export default {
  name: 'pen',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Input.name]: Input,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    basicHeader,
    searchInput,
    qlPagination,
    Dialog,
  },
  data() {
    return {
      TYPE_STUDENT,
      TYPE_PUBLIC,
      TYPE_TEACHER,

      types: [
        {
          id: '0',
          name: '全部',
        },
        {
          id: TYPE_STUDENT,
          name: '学生点阵笔',
        },
        // {
        //   id: TYPE_PUBLIC,
        //   name: '公共点阵笔',
        // },
        {
          id: TYPE_TEACHER,
          name: '教师点阵笔',
        },
      ],

      table: {
        list: [],
        total: 0,
        pageSize: 10,
        pageIndex: 1,
        type: '0',
        gradeId: '',
      },

      multipleSelection: [],

      dialogVisible: false,
      dialogIsEdit: false,
      dialogDefaultvalue: undefined,

      subTitle: '',
    };
  },
  methods: {
    // handle select
    handleChangeType() {
      this.table.pageIndex = 1;
      this.multipleSelection = [];
      this.$refs.table.clearSelection();
      this.getPenPageJSON();
    },
    // handle search
    handleSearch() {
      this.table.pageIndex = 1;
      this.getPenPageJSON();
    },
    // handle 多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // handle 新增点阵笔
    handleAdd() {
      this.dialogIsEdit = false;
      this.dialogVisible = true;
      this.dialogDefaultvalue = undefined;
    },
    // handle 编辑
    handleEdit(val) {
      this.dialogIsEdit = true;
      this.dialogVisible = true;
      this.dialogDefaultvalue = val;
    },
    // handle 批量删除
    handleBatchDelete() {
      this.$msgbox
        .confirm(`确定批量删除这${this.multipleSelection.length}支点阵笔吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          deletePen({ ids: this.multipleSelection.map((x) => x.id) }).then(() => {
            this.$message({
              type: 'success',
              message: '点阵笔批量删除成功!',
            });
            this.multipleSelection = [];
            this.$refs.table.clearSelection();
            this.getPenPageJSON(1);
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '操作已取消',
          });
        });
    },
    // handle 删除
    handleDelete(val) {
      this.$msgbox
        .confirm('确定删除此点阵笔吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          deletePen({ ids: [val.id] }).then(() => {
            this.$message({
              type: 'success',
              message: '点阵笔删除成功!',
            });
            this.getPenPageJSON();
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '操作已取消',
          });
        });
    },
    // api
    getPenPageJSON(pageIndex) {
      getPenPageJSON({
        type: this.table.type,
        search: this.table.search,
        pageSize: this.table.pageSize,
        pageIndex: pageIndex || this.table.pageIndex,
      }).then(({ result }) => {
        this.table.list = result.data;
        this.table.total = result.recordCount;
        this.table.pageIndex = result.pageIndex;
      });
    },
    // 获取学校适用的点阵笔型号
    getSchoolPrintPlan() {
      getSchoolPrintPlan({
        schoolId: this.$store.state.user.userInfo.schoolId,
      }).then(({ result }) => {
        if (result.deviceList && result.deviceList.length) {
          this.subTitle = '本校仅适用于' + result.deviceList.map((item) => item.deviceName).join('、') + '型号点阵笔';
        } else {
          this.subTitle = '暂未找到本校适用的点阵笔型号，请联系青鹿技服配置铺码方案';
        }
      });
    },
    initPage() {
      this.getSchoolPrintPlan();
      this.getPenPageJSON();
    },

    // 批量录入
    handleImport() {
      this.$batchEntry({
        type: 'pen',
        UNIT: '支',
        callback: () => {
          this.table.type = '0';
          this.table.search = '';
          this.table.pageIndex = 1;
          this.getPenPageJSON();
        },
      });
    },

    // 批量导出
    handleExport() {
      this.$batchExport({
        type: 'pen',
      });
    },
  },
  created() {
    this.initPage();
  },
};
